/* eslint-disable @typescript-eslint/no-explicit-any */
/*******************************************************
Copyright (C) 2024 H2 Digital GmbH - info@h2-digital.com , Inc - All Rights Reserved
Proprietary and confidential.
Unauthorized copying of this file, via any medium is strictly prohibited.
Written by Darshan Sannamuddaiah, darshan.sannamuddaiah@h2-digital.com, November 2024
*******************************************************/
import { FunctionComponent, useEffect, useState } from 'react';
import { IPowerBIDetails } from 'interfaces/powerBi.interface';
import SPowerBIService from 'services/powerBI/powerBI.service';
import { PowerBIComponent } from 'components/powerBI/PowerBI.component';

export const HyPortalProviders: FunctionComponent = () => {
  const [reportDetails, setReportDetails] = useState<IPowerBIDetails>();

  useEffect(() => {
    SPowerBIService.getHyPortalProviderDashboard()
      .then((reportData) => {
        setReportDetails(reportData);
      })
      .catch((err) => {
        console.error('Error fetching report details:', err);
      });
  }, []);

  return reportDetails ? (
    <PowerBIComponent
      accessToken={reportDetails.token}
      dashboardId={reportDetails.id}
      embedUrl={reportDetails.embedURL}
    />
  ) : (
    <></>
  );
};
