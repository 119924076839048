import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material';
import './TSOSetup.scss';
import {
  ChangeEvent,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import ImageIcon from '@mui/icons-material/Image';
import {
  DomainNames,
  ICreateUserDto,
  IUserDetails,
  IUserDetailsError,
} from 'interfaces/user.interface';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { debounce, matches } from 'lodash';
import SUserService from 'services/user/user.service';
import STeamsService from 'services/teams/teams.service';
import { NewUserValidator } from 'utils/UserValidator';
import { addNewUserToProjectMsgTeams } from 'utils/teamsMessage';
import { h2DigitalTeamsForNotification } from 'config/authConfig';
import { ILocationDetails } from 'interfaces/location.interface';
import { UserTypes } from 'constants/user';
import { findTag } from 'utils/tags';
import { TagKeys } from 'constants/tag';

interface IUserSetupProps {
  setNewUserDetails: Dispatch<SetStateAction<IUserDetails | undefined>>;
  providerLocationDetails: Partial<ILocationDetails>;
}

const defaultUserValues = {
  firstName: '',
  lastName: '',
  username: '',
  secondaryEmail: '',
  domain: DomainNames.h2Digital,
  isAdmin: true,
  isLocked: false,
  isSuperUser: false,
  userType: UserTypes.Provider,
  tagIds: [],
  locationIds: [],
};

export const UserFragment: FunctionComponent<IUserSetupProps> = (props) => {
  const { setNewUserDetails, providerLocationDetails } = props;

  const userLogoInput = useRef<HTMLInputElement>(null);
  const [userLogoUrl, setUserLogoUrl] = useState<string>('');
  const [userNameExists, setUserNameExists] = useState<boolean>();
  const [isLoading, setLoading] = useState(false);
  const [userAvatar, setUserAvatar] = useState<Blob>();
  const [newUserPayload, setNewUserPayload] =
    useState<ICreateUserDto>(defaultUserValues);
  const [userDetailsError, setUserDetailsError] = useState<IUserDetailsError>({
    firstName: '',
    lastName: '',
    username: '',
    secondaryEmail: '',
    userAvatar: '',
  });
  const { showSnackbar } = useSnackbar();

  const handleUserLogoClick = () => {
    userLogoInput?.current?.click();
  };
  const handleUserLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    setUserDetailsError({ ...userDetailsError, userAvatar: '' });
    setUserAvatar(event.target.files[0]);
  };
  const handleLogoUrlChange = (event: { target: { value: string } }) => {
    setUserLogoUrl(event.target.value);
  };
  const handleUploadLogo = async () => {
    if (userLogoUrl.trim() !== '') {
      fetch(userLogoUrl)
        .then((response) => response.blob())
        .then((file) => {
          if (file.type.startsWith('image/')) {
            setUserDetailsError({
              ...userDetailsError,
              userAvatar: '',
            });
            setUserAvatar(file);
            setUserLogoUrl('');
          } else {
            showSnackbar('Fetch Logo Failed!', 'error');
            setUserLogoUrl('');
          }
        })
        .catch(() => {
          showSnackbar('Fetch Logo Failed!', 'error');
          setUserLogoUrl('');
        });
    }
  };

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value =
      event.target.name == 'isAdmin'
        ? event.target.checked
        : event.target.value;
    setUserDetailsError({
      ...userDetailsError,
      [name]: '',
    });
    setNewUserPayload({ ...newUserPayload, [name]: value });
  };

  const checkUserNameExists = (signal: AbortSignal) => {
    const userid =
      newUserPayload.username?.toLowerCase() + DomainNames.h2Digital;
    const delayedCheck = debounce(async () => {
      const exists = await SUserService.checkH2DigitalUserExists(
        userid,
        signal
      );
      setUserNameExists(exists.data);
    }, 500);
    delayedCheck();
  };

  useEffect(() => {
    setUserNameExists(undefined);
    const controller = new AbortController();
    const signal = controller.signal;
    if (newUserPayload.username?.trim().length) checkUserNameExists(signal);
    else setUserNameExists(undefined);
    return () => {
      controller.abort();
    };
  }, [newUserPayload.username]);

  const createNewUser = () => {
    if (!isLoading) {
      setUserDetailsError({
        firstName: '',
        lastName: '',
        username: '',
        secondaryEmail: '',
        userAvatar: '',
      });
      const errors = NewUserValidator(newUserPayload, userAvatar);
      setUserDetailsError((prev) => {
        return { ...prev, ...errors };
      });
      if (
        matches(errors)({}) &&
        userAvatar &&
        !userNameExists &&
        providerLocationDetails?.id
      ) {
        setLoading(true);
        if (providerLocationDetails.tags) {
          const providerTag = findTag(
            providerLocationDetails.tags,
            TagKeys.Provider
          );
          if (providerTag) {
            const payload = {
              ...newUserPayload,
              locationIds: [providerLocationDetails.id],
              tagIds: providerTag?.id ? [providerTag.id] : [],
            };
            SUserService.createUser(payload)
              .then((res) => {
                const { aadUser, userDetails } = res;
                setNewUserDetails(userDetails);
                if (userDetails) {
                  const message = addNewUserToProjectMsgTeams({
                    locationName: providerLocationDetails?.name ?? '',
                    locationId: String(providerLocationDetails?.id) ?? '',
                    user: {
                      username: aadUser.emailAddress,
                      password: aadUser.password,
                    },
                  });
                  if (h2DigitalTeamsForNotification?.link) {
                    STeamsService.sendNotificationInTeams(
                      h2DigitalTeamsForNotification.link,
                      message
                    );
                  }
                }
                SUserService.uploadAvatar(userAvatar, userDetails.id);
                showSnackbar(
                  'Provider user creation success. Please complete teams set up also.',
                  'success'
                );
              })
              .catch(() => {
                showSnackbar('Provider user creation failed.', 'error');
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            showSnackbar(
              'No Provider tag present for the given location.',
              'error'
            );
            setLoading(false);
          }
        } else {
          showSnackbar('No tags assigned to provider location.', 'error');
          setLoading(false);
        }
      }
    }
  };

  return (
    <Grid container xs={12} className="project-container">
      <Grid xs={4} className="project-image-container">
        <Grid xs={12} className="project-image">
          <div className="project-image-title">User Image*</div>
          {userAvatar ? (
            <div className="project-image-wrap">
              <img
                className="project-img"
                src={URL.createObjectURL(userAvatar)}
                onClick={handleUserLogoClick}
              />
            </div>
          ) : (
            <div className="project-image-wrap">
              <ImageIcon
                className="project-image-icon"
                onClick={handleUserLogoClick}
              />
            </div>
          )}
          <div className="error-class">
            {userDetailsError?.userAvatar ? 'User Logo cannot be empty' : ''}
          </div>
          <input
            type="file"
            ref={userLogoInput}
            onChange={handleUserLogoChange}
            style={{ display: 'none' }}
            accept="image/png, image/jpeg"
          />
          <div className="image-url-wrapper">
            <Tooltip title="Enter the image URL to fetch the image">
              <TextField
                size="small"
                id="image-text-input"
                type="text"
                onChange={handleLogoUrlChange}
                onBlur={handleUploadLogo}
                value={userLogoUrl}
                placeholder="Enter image URL"
              />
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <Grid xs={8} container spacing={1} className="project-details-container">
        <Grid item xs={4} className="project-detail-input-container">
          <TextField
            className="project-detail-input"
            required
            autoFocus
            margin="dense"
            id="firstName"
            label="First Name"
            type="text"
            variant="outlined"
            value={newUserPayload?.firstName || ''}
            error={userDetailsError.firstName ? true : false}
            helperText={userDetailsError.firstName || ''}
            name="firstName"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={4} className="project-detail-input-container">
          <TextField
            className="project-detail-input"
            required
            margin="dense"
            id="lastName"
            label="Last Name"
            type="text"
            variant="outlined"
            value={newUserPayload?.lastName || ''}
            error={userDetailsError.lastName ? true : false}
            helperText={userDetailsError.lastName || ''}
            name="lastName"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={4} className="project-detail-input-container">
          <TextField
            className="project-detail-input"
            required
            margin="dense"
            id="secondaryEmail"
            label="Secondary Email"
            type="text"
            variant="outlined"
            value={newUserPayload?.secondaryEmail || ''}
            error={userDetailsError.secondaryEmail ? true : false}
            helperText={userDetailsError.secondaryEmail || ''}
            name="secondaryEmail"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={4} className="project-detail-input-container">
          <TextField
            className="project-detail-input"
            required
            margin="dense"
            id="username"
            label="Username"
            type="text"
            variant="outlined"
            value={newUserPayload?.username || ''}
            error={
              userDetailsError.username ||
              (userNameExists && Boolean(newUserPayload.username))
                ? true
                : false
            }
            helperText={
              userDetailsError.username ||
              (userNameExists && Boolean(newUserPayload.username)
                ? `${newUserPayload.username} already exists`
                : '')
            }
            name="username"
            onChange={handleFormChange}
          />
        </Grid>
        <Grid item xs={4} className="project-detail-input-container">
          <TextField
            disabled
            className="project-detail-input"
            required
            margin="dense"
            id="domain"
            label="Domain"
            type="text"
            variant="outlined"
            value={DomainNames.h2Digital}
            name="domain"
          />
        </Grid>
        <Grid item xs={2} className="project-detail-input-container">
          <FormControlLabel
            disabled
            control={
              <Checkbox
                name="isAdmin"
                id="isAdmin"
                value={newUserPayload?.isAdmin || false}
                checked={newUserPayload.isAdmin}
                onChange={handleFormChange}
              />
            }
            label="IsAdmin"
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={createNewUser}>
            {isLoading ? <CircularProgress /> : 'Create New User '}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
