import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  TablePagination,
} from '@mui/material';
import { pick } from 'lodash';

import './ListLocation.scss';
import { FaEdit } from 'react-icons/fa';
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { ILocationDetails } from 'interfaces/location.interface';
import { LocationType, locationTypeMap } from 'constants/location';
import SUserService from '../../services/user/user.service';
import SLocationService from '../../services/location/location.service';
import SGridDataPlanningService from '../../services/gridDataPlanning/gridDataPlanning.service';
import { encodeURLPublicJoin } from 'utils/location';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ImageIcon from '@mui/icons-material/Image';
import { User } from '@microsoft/microsoft-graph-types';

const TableWrapper = styled.p`
  height: 400;
  width: '50%';
  margin-top: 20px;
  font-family: 'Inria Sans';
`;

interface IListlocations {
  locationsList: ILocationDetails[];
  handleDeletelocation: (id: string) => void;
}

const ListLocations = (props: IListlocations) => {
  const { locationsList, handleDeletelocation } = props;
  const [open, setOpen] = useState(false);
  const [openVerified, setOpenVerified] = useState(false);
  const [selectedlocationId, setSelectedlocationId] = useState('');
  const [usernameVerification, setUsernameVerification] = useState('');
  const [currentUser, setCurrentUser] = useState<Partial<User>>({ mail: '' });
  const [invalidUserName, setInvalidUserName] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [singleLocation, setSingleLocation] = useState(false);
  const [editLocation, setEditLocation] = useState(false);
  const [loadGridDataPlanningCheckbox, setLoadGridDataPlanningCheckbox] =
    useState(false);
  const [selectedLocation, setSelectedLocation] = useState<number>();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateSnackbarOpen, setUpdateSnackbarOpen] = useState(false);
  const [urlCopySnackbar, setURLCopySnackbar] = useState(false);
  const [newlocationData, setNewLocationData] =
    useState<Partial<ILocationDetails | undefined>>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const locationLogoInput = useRef<HTMLInputElement>(null);
  const [locationLogo, setLocationLogo] = useState<Blob | null>();
  const [locationNameError, setLocationNameError] = useState(false);

  const handleClickOpen = (locationId: string) => {
    setSelectedlocationId(locationId);
    setOpen(true);
  };
  const handleChangePage = (event: MouseEvent | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleClickOpenVerified = () => {
    if (currentUser.mail === usernameVerification) {
      setOpenVerified(true);
    } else {
      setInvalidUserName(true);
      setStatusOpen(true);
    }
  };

  const handleCloseEdit = () => {
    setEditLocation(false);
    setLocationNameError(false);
    setLoadGridDataPlanningCheckbox(false);
  };

  const handleUpdateLocation = async () => {
    try {
      if (newlocationData?.name?.trim().length) {
        // Setting OGEDigitalization Level as mandatory
        setLocationNameError(false);
        if (selectedLocation) {
          if (newlocationData?.id) {
            const restData = pick(
              newlocationData,
              'name',
              'logo',
              'connectorUrl'
            );
            if (locationLogo) {
              try {
                const logoUrl = await SLocationService.uploadLogo(
                  locationLogo,
                  selectedLocation
                );
                restData.logo = logoUrl;
              } catch (err) {
                console.log('Error uploading location logo', err);
              }
            }
            SLocationService.updateLocationDetails(selectedLocation, restData)
              .then((res) => {
                setNewLocationData(res.data);
                setLocationLogo(null);
              })
              .catch((err: Error) => {
                console.error('Error updating location', err);
                setLocationLogo(null);
              });
          }
          if (loadGridDataPlanningCheckbox)
            //TODO: add response message, error check
            SGridDataPlanningService.addGridDataPlanningRowsNew(
              selectedLocation
            ).then(() => {
              setLoadGridDataPlanningCheckbox(false);
            });
          setEditLocation(false);
          setUpdateSuccess(true);
          setUpdateSnackbarOpen(true);
        }
      } else {
        setLocationNameError(true);
      }
    } catch (error) {
      setEditLocation(false);
      setUpdateSuccess(false);
      setUpdateSnackbarOpen(false);
      console.log(error);
    }
  };

  const onEditLocation = (value: number | string, key: string) => {
    setNewLocationData({ ...newlocationData, [key]: value });
  };

  const handleClose = () => {
    setSelectedlocationId('');
    setUsernameVerification('');
    setStatusOpen(false);
    setOpen(false);
  };
  const handleCloseVerified = () => {
    setSelectedlocationId('');
    setUsernameVerification('');
    setOpenVerified(false);
    handleClose();
  };

  const onDeleteConfirm = useCallback(() => {
    handleDeletelocation(selectedlocationId);
    handleCloseVerified();
  }, [handleDeletelocation, selectedlocationId]);

  const handleStatusClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason && reason === 'clickaway') {
      setStatusOpen(false);
      return;
    }
  };

  const currentLocationDetails = async (locationId: number) => {
    setSelectedLocation(locationId);
    const locationData = await SLocationService.getLocationDetails(locationId);
    setNewLocationData(locationData);
  };

  const handleOpenAlert = () => {
    setSingleLocation(true);
  };

  const handleSingleLocationAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway' || !reason) {
      setSingleLocation(false);
      return;
    }
  };

  const handleUpdateAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateSnackbarOpen(false);
  };

  const createURLShare = async () => {
    encodeURLPublicJoin({ ...newlocationData });
    setURLCopySnackbar(true);
    setTimeout(() => setURLCopySnackbar(false), 1000);
  };

  useEffect(() => {
    SUserService.getUser().then((user: User) => {
      setCurrentUser(user);
    });
  }, []);

  const handleLocationLogoClick = () => {
    locationLogoInput.current?.click();
  };

  const handleLocationLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    setLocationLogo(file);
  };

  return (
    <>
      {locationsList.length ? (
        <TableWrapper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow selected>
                  <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                    Id
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                    Name
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                    Description
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                    Address
                  </TableCell>
                  <TableCell align="center" sx={{ fontFamily: 'Inria Sans' }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? locationsList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : locationsList
                ).map((location) => (
                  <TableRow
                    key={location.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                      {location.id}
                    </TableCell>
                    <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                      {location.name}
                    </TableCell>
                    <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                      {location.description}
                    </TableCell>
                    <TableCell align="left" sx={{ fontFamily: 'Inria Sans' }}>
                      {location.address}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            if (locationsList.length === 1) {
                              handleOpenAlert();
                            } else {
                              handleClickOpen(location.id.toString());
                            }
                          }}
                        >
                          <FaRegTrashAlt color="red" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => {
                            setEditLocation(true);
                            currentLocationDetails(location.id);
                          }}
                        >
                          <FaEdit color="blue" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 30]}
              component="div"
              count={locationsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete location</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Verify your email to proceed
              </DialogContentText>
              <TextField
                size="medium"
                type="string"
                value={usernameVerification}
                onChange={(event) => {
                  setUsernameVerification(event.target.value);
                }}
                inputProps={{
                  style: {
                    border: ' 0.5px solid #5F9CD9',
                    padding: '5px 14px',
                  },
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button color="error" onClick={handleClickOpenVerified} autoFocus>
                Verify
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openVerified}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete Location</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to delete this location permanently ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseVerified}>Cancel</Button>
              <Button color="error" onClick={onDeleteConfirm} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </TableWrapper>
      ) : null}
      <Dialog
        open={editLocation}
        onClose={handleCloseEdit}
        className="dialog-location"
      >
        <DialogTitle>
          <div className="title-location">
            {'Edit Location Data'}
            {newlocationData?.H2DigitalType == LocationType.Provider && (
              <>
                <Tooltip title="Copy URL to invite partner">
                  <IconButton onClick={createURLShare}>
                    {urlCopySnackbar && (
                      <div className="snackbar-url-copy">URL copied</div>
                    )}
                    <ContactMailIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        </DialogTitle>
        <DialogContent className="dialogue-wrapper">
          <TextField
            type="text"
            label="Location Name"
            required
            value={newlocationData?.name ? newlocationData?.name : ''}
            onChange={(event) => {
              onEditLocation(event.target.value, 'name');
            }}
            sx={{ marginBottom: '40px' }}
            helperText={
              locationNameError ? 'Location Name cannot be empty' : ''
            }
            error={locationNameError}
          />
          <FormControl required sx={{ mb: 5, minWidth: 250 }}>
            <InputLabel id="H2 Digital Type">H2 DigitalType</InputLabel>
            <Select
              labelId=" H2 Digital Type"
              id="h2-digital-type-select"
              size="medium"
              sx={{ fontFamily: 'Inria Sans' }}
              label="H2 Digital Type"
              value={
                newlocationData?.H2DigitalType
                  ? newlocationData?.H2DigitalType
                  : ''
              }
              onChange={(event) => {
                onEditLocation(event.target.value, 'H2DigitalType');
              }}
            >
              {locationTypeMap?.map((element) => {
                return (
                  <MenuItem key={element.value} value={element.value}>
                    {element?.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            type="text"
            label="Web Hook URL"
            value={
              newlocationData?.connectorUrl ? newlocationData?.connectorUrl : ''
            }
            onChange={(event) => {
              onEditLocation(event.target.value, 'connectorUrl');
            }}
            sx={{ marginBottom: '40px' }}
          />
          <span className="logo-label">Logo</span>
          {!newlocationData?.logo && !locationLogo && (
            <div className="edit-project-logo-wrap">
              <ImageIcon
                className="new-project-image-icon"
                onClick={handleLocationLogoClick}
              />
            </div>
          )}
          {(newlocationData?.logo || locationLogo) && (
            <div className="edit-project-logo-wrap">
              <img
                className="new-project-image"
                src={
                  locationLogo
                    ? URL.createObjectURL(locationLogo)
                    : newlocationData?.logo
                }
                onClick={handleLocationLogoClick}
              />
            </div>
          )}
          <input
            type="file"
            ref={locationLogoInput}
            onChange={handleLocationLogoChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
          <label className="checkbox-wrap">
            <input
              type="checkbox"
              checked={loadGridDataPlanningCheckbox}
              onChange={() => {
                setLoadGridDataPlanningCheckbox(!loadGridDataPlanningCheckbox);
              }}
            />
            Add new Grid Data Planning Data
          </label>
        </DialogContent>
        <DialogActions sx={{ paddingTop: '50px' }}>
          <div className="button-wrapper">
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button onClick={handleUpdateLocation} variant="contained">
              {'Update Location Details'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={statusOpen}
        autoHideDuration={1000}
        onClose={handleStatusClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Alert
          onClose={handleStatusClose}
          severity={!invalidUserName ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {invalidUserName ? 'Email entered is invalid.' : ''}
        </Alert>
      </Snackbar>
      <Snackbar
        open={singleLocation}
        autoHideDuration={4000}
        onClose={handleSingleLocationAlertClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Alert
          onClose={handleSingleLocationAlertClose}
          severity={!singleLocation ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {singleLocation
            ? 'Selected Location cannot be deleted as there are no other locations associated with the project'
            : ''}
        </Alert>
      </Snackbar>
      <Snackbar
        open={updateSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleUpdateAlertClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Alert
          onClose={handleUpdateAlertClose}
          severity={updateSuccess ? 'success' : 'error'}
        >
          {updateSuccess
            ? 'Location Data updated successfully'
            : 'Location update failed'}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ListLocations;
