import { emailExpression } from 'constants/common';
import { ICreateUserDto } from 'interfaces/user.interface';

export const NewUserValidator = (
  newUserDetails: ICreateUserDto,
  userAvatar: Blob | undefined
) => {
  let userError = {};
  if (newUserDetails.firstName === '' || newUserDetails.firstName === null) {
    userError = {
      ...userError,
      firstName: 'First Name cannot be empty!',
    };
  }
  if (newUserDetails.lastName === '' || newUserDetails.lastName === null) {
    userError = {
      ...userError,
      lastName: 'Last Name cannot be empty!',
    };
  }
  if (newUserDetails.username === '' || newUserDetails.username === null) {
    userError = {
      ...userError,
      username: 'Username cannot be empty!',
    };
  } else if (newUserDetails.username.includes(' ')) {
    userError = {
      ...userError,
      userName: 'White space not allowed!',
    };
  }
  if (userAvatar === undefined) {
    userError = {
      ...userError,
      userAvatar: 'User Avatar cannot be empty!',
    };
  }
  if (
    newUserDetails.secondaryEmail.trim() === '' ||
    newUserDetails.secondaryEmail === null
  ) {
    userError = {
      ...userError,
      secondaryEmail: 'Secondary email cannot be empty!',
    };
  } else {
    const isValid = emailExpression.test(newUserDetails.secondaryEmail);
    if (!isValid) {
      userError = {
        ...userError,
        secondaryEmail: 'Enter a valid email address!',
      };
    }
  }

  return userError;
};

export const InviteUserValidator = (newUserDetails: ICreateUserDto) => {
  let userError = {};
  if (
    newUserDetails.secondaryEmail.trim() === '' ||
    newUserDetails.secondaryEmail === null
  ) {
    userError = {
      ...userError,
      secondaryEmail: 'An email to invite cannot be empty!',
    };
  } else {
    const isValid = emailExpression.test(newUserDetails.secondaryEmail);
    if (!isValid) {
      userError = {
        ...userError,
        secondaryEmail: 'Enter a valid email address!',
      };
    }
  }

  return userError;
};
