import { IUserDetails } from 'interfaces/user.interface';

interface IUserTypeMap {
  label: string;
  value: number;
}

export enum UserTypes {
  H2DigitalUser = 5,
  Provider = 10,
  ProjectUser = 20,
}

export const mapUserType: IUserTypeMap[] = [
  { value: UserTypes.H2DigitalUser, label: 'H2 Digital User' },
  { value: UserTypes.Provider, label: 'Provider User' },
  { value: UserTypes.ProjectUser, label: 'Project User' },
];

export const InitialUserDetails: Partial<IUserDetails> = {
  emailAddress: '',
  description: '',
  isAdmin: false,
  isLocked: false,
  isSuperUser: false,
  secondaryEmail: '',
  userType: UserTypes.ProjectUser,
};

export enum UserCreationMode {
  INVITE,
  CREATE,
}
