/* eslint-disable @typescript-eslint/no-explicit-any */
import { PowerBIEmbed } from 'powerbi-client-react';
import { Embed, models } from 'powerbi-client';
import './PowerBI.component.scss';
import { FunctionComponent, useEffect, useState } from 'react';
import PowerBILoadingComponent from './PowerBILoading.component';

import { FallBack } from '../../pages/Fallback/fallback.component';
import { getPowerBIEmbedConfig } from 'constants/reports';

interface IPowerBIProps {
  accessToken: string;
  embedUrl: string;
  dashboardId: string;
  filters?: any;
  isTokenAad?: boolean;
  displayOption?: string;
  customClass?: string;
  customLoader?: React.ReactNode;
}

export const PowerBIComponent: FunctionComponent<IPowerBIProps> = (props) => {
  const [reportComponent, setReportComponent] = useState<Embed>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { accessToken, embedUrl, dashboardId, customClass, customLoader } =
    props;

  useEffect(() => {
    reportComponent?.on('rendered', async (event: any) => {
      const pages = await event?.srcElement?.powerBiEmbed.getPages();
      setLoading(false);
      console.log(pages.map((item: { name: any }) => item.name)); // Log pages instead of setting state
    });

    reportComponent?.on('error', (event: any) => {
      const err = event.detail;
      setLoading(false);
      // If the error level isn't Fatal, log the error and continue.
      if (err?.level !== models.TraceType.Fatal) {
        console.error(err);
        return;
      } else {
        setError(true);
        console.log(error);
      }
    });

    return () => {
      reportComponent?.off('rendered');
      reportComponent?.off('pageChanged');
      reportComponent?.off('error');
    };
  }, [reportComponent]);

  const powerBIEmbedConfig = getPowerBIEmbedConfig(
    dashboardId,
    accessToken,
    embedUrl
  );

  return (
    <div
      className={'report-container' + (customClass ? ` ${customClass}` : '')}
      id="drawer-container"
    >
      {loading && <PowerBILoadingComponent child={customLoader} />}
      {embedUrl && !error ? (
        <>
          <PowerBIEmbed
            getEmbeddedComponent={(embeddedReport) => {
              setReportComponent(embeddedReport);
            }}
            embedConfig={powerBIEmbedConfig}
            cssClassName="report"
          />
        </>
      ) : error ? (
        <FallBack
          error={new Error('Something Went Wrong. Please try Again!')}
          resetErrorBoundary={() => {}}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
