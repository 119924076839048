import { mapUserType, UserTypes } from 'constants/user';

export const addNewUserToProjectMsgTeams = (data: {
  locationName: string;
  locationId: string;
  user: { username: string; password: string };
}) => {
  const message =
    'A new User is added to the location ' +
    data.locationName +
    ' with Location id : ' +
    data.locationId +
    '. ' +
    'The username is ' +
    data.user.username +
    ' and password ' +
    data.user.password;
  return message;
};

export const inviteUserMsgTeams = (data: {
  locationNames: string[];
  locationIds: number[];
  user: { username: string; userType: UserTypes };
}) => {
  const message =
    'A new User is invited to H2 Digital app with user type ' +
    data.user.userType +
    '(' +
    mapUserType[data.user.userType] +
    '). He is assigned to locations ' +
    data.locationNames.join(', ') +
    ' (location ids : ' +
    data.locationIds.join(', ') +
    '). ' +
    'The email is ' +
    data.user.username;
  return message;
};
