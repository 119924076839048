import Sidebar from '../components/Sidebar/Sidebar';
import { Routes, Route } from 'react-router-dom';
import './PageContainer.component.scss';
import Project from './Project/Project';
import Reports from './Reports/Reports';
import Location from '../pages/Location/Location';
import Users from './Users/Users';
import { useState, useEffect } from 'react';
import { HeaderComponent } from '../components/Header/Header.component';
import { ProfileContainerComponent } from '../components/ProfileContainer/ProfileContainer.component';
import { FallBack } from './Fallback/fallback.component';
import TSOSetup from './TSOSetup/TSOSetup';
import DriverFunction from './DriverFunction/DriverFunction';
import Teams from './Teams/Teams';
import Tags from './Tags/Tags';
import { Map } from './EmbeddedIFrame/Map';
import { SupportPage } from './Support/support.page';
import { HyPortalProviders } from './HyPortalProviders/hyportalProviders.page';
import SUserService from 'services/user/user.service';

export const PageContainerComponent = () => {
  const [sidebarClose, setSidebarClose] = useState(false);
  const [error, setError] = useState(false);
  const [errorStatus, setErrorStatus] = useState<Error | null>(null);
  const [hideSideMenu, setHideSideMenu] = useState(false);
  const getUserdetails = () => {
    SUserService.getUserDetails()
      .then((user) => {
        const admin = user.isSuperUser;
        if (!admin) {
          setErrorStatus(new Error('Unauthorized user'));
          setError(true);
        }
      })
      .catch((err: Error) => {
        setErrorStatus(err);
        console.log('err', err);
        setError(true);
      });
  };

  useEffect(() => {
    getUserdetails();
    setHideSideMenu(
      ['/support', '/hyportal/providers'].includes(location.pathname)
    );
  }, []);

  return (
    <div className="wrapper-container">
      {!error ? (
        <div
          className={`page-container ${
            !hideSideMenu && !sidebarClose ? 'sidebar-open' : ''
          }`}
        >
          {' '}
          {!hideSideMenu && (
            <Sidebar close={sidebarClose} setClose={setSidebarClose}></Sidebar>
          )}
          <div
            className={`right-column ${
              !hideSideMenu && !sidebarClose ? 'sidebar-open-right-column' : ''
            }`}
          >
            {' '}
            {!hideSideMenu && (
              <div className="header-container">
                <div className="header-component">
                  <HeaderComponent />
                </div>
                <div className="profile-component">
                  <ProfileContainerComponent />
                </div>
              </div>
            )}
            <Routes>
              <Route path="/" element={<Project />} />
              <Route path="/location" element={<Location />} />
              <Route path="/users" element={<Users />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/provider-setup" element={<TSOSetup />} />
              <Route path="/driverfunction" element={<DriverFunction />} />
              <Route path="/teams" element={<Teams />} />
              <Route path="/tags" element={<Tags />} />
              <Route path="/embeddedIFrame" element={<Map />} />
              <Route path="/support" element={<SupportPage />} />
              <Route
                path="/hyportal/providers"
                element={<HyPortalProviders />}
              />
            </Routes>
          </div>
        </div>
      ) : errorStatus ? (
        <FallBack error={errorStatus} resetErrorBoundary={() => {}} />
      ) : (
        <></>
      )}
    </div>
  );
};
