/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, createContext, useContext, ReactNode } from 'react';

import SnackbarComponent from './Snackbar.component';

import AlertComponent from 'components/alerts/Alert.component';

// Create a context for the Snackbar
const SnackbarContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showSnackbar: (message: string, severity?: Severity, timeMs?: number) => {},
  hideSnackbar: () => {},
});

interface SnackbarProviderProps {
  children: ReactNode;
}

type Severity = 'success' | 'warning' | 'info' | 'error';

function SnackbarProvider({ children }: SnackbarProviderProps) {
  const [open, setOpen] = useState(false);
  const [messageState, setMessage] = useState('');
  const [timeState, setTime] = useState<number | undefined>();
  const [severityState, setSeverity] = useState<Severity>('info');

  const showSnackbar = (
    message: string,
    severity?: Severity,
    timeMs?: number
  ) => {
    if (severity) setSeverity(severity);
    setMessage(message);
    setOpen(true);
    setTime(timeMs);
  };

  const hideSnackbar = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      {children}
      <SnackbarComponent
        autoHideDuration={timeState}
        open={open}
        onClose={hideSnackbar}
      >
        <AlertComponent
          onClose={hideSnackbar}
          severity={severityState}
          message={messageState}
          variant="filled"
        />
      </SnackbarComponent>
    </SnackbarContext.Provider>
  );
}

// Custom hook to use the Snackbar context
function useSnackbar() {
  return useContext(SnackbarContext);
}

export { SnackbarProvider, useSnackbar };
