import { FunctionComponent } from 'react';
import './TagFilter.component.scss';
import { TagSearch } from 'components/tagSearchAndFilter/TagSearch.component';
import { CreateTagDto, ITag } from 'interfaces/tag.interface';

interface ITagFilterProps {
  tags: ITag[];
  onChange: (tags: ITag[]) => void;
}

export const TagFilter: FunctionComponent<ITagFilterProps> = ({
  tags,
  onChange,
}) => {
  const onSubmit = (tag: Pick<ITag, 'key' | 'value'>) => {
    // Search component submits objects with at least key&value defined
    // but when creation is not allowed, it only submits existing tags with id, that's why we cast below
    const newSelectedTags = [...tags, tag as ITag];
    onChange(newSelectedTags);
  };

  const isPossibleToSelect = (tag: CreateTagDto) => {
    return !tags.find((t) => t.id === (tag as ITag).id);
  };

  return (
    <div className="tag-filter-root">
      <TagSearch
        className="tag-search-in-filters"
        searchFieldsClassName="tag-search-field"
        onSubmit={onSubmit}
        isCreateTagAllowed={false}
        isPossibleToSelect={isPossibleToSelect}
        notPossibleToSelectMessage={'Not possible to select'}
      />
    </div>
  );
};
