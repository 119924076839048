import { IReportDetails } from 'interfaces/reports.interface';

export const reportInputValidator = (
  reportDetails: Partial<IReportDetails>
) => {
  let reportError = {};
  if (!reportDetails?.reportId) {
    reportError = { ...reportError, reportId: 'ReportId is mandatory!' };
  }
  if (!reportDetails?.workspaceId) {
    reportError = {
      ...reportError,
      workspaceId: 'WorkspaceId is mandatory!',
    };
  }
  if (!reportDetails?.datasetId) {
    reportError = { ...reportError, datasetId: 'DatasetId is mandatory!' };
  }
  return reportError;
};
