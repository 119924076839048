import { Button, FormControl } from '@mui/material';
import './DriverFunction.scss';
import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import DriverFunctionList from './DriverFunctionList';
import AddDriverFunctionDialog from './AddDriverFunctionDialog';
import SDriverFunctionService from 'services/driverFunction/driverFunction.service';
import { DriverFunctionData } from 'interfaces/driverFunction.interface';
import LocationAutocomplete from 'components/LocationAutocomplete/LocationAutocomplete';
import SLocationService from 'services/location/location.service';
import {
  ILocationDetails,
  ILocationMiniInfo,
} from 'interfaces/location.interface';

const DriverFunction: FunctionComponent = () => {
  const [selectedLocationId, setSelectedLocationId] = useState<
    number | undefined
  >(undefined);
  const [locationList, setLocationList] = useState<ILocationDetails[]>([]);
  const [openNewDriverFunction, setOpenNewDriverFunction] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [list, setList] = useState<DriverFunctionData[]>();
  const newDriverFunction = {
    locationId: selectedLocationId,
    type: '',
    function: '',
    name: '',
  };

  const onSelectedProjectChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: ILocationMiniInfo | null
  ) => {
    setSelectedLocationId(newValue?.id);
  };

  const handleNewDriverFunction = () => {
    setOpenNewDriverFunction(true);
  };
  useEffect(() => {
    SLocationService.getAllLocationByType()
      .then((response) => {
        setLocationList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (selectedLocationId !== undefined && selectedLocationId)
      SDriverFunctionService.getDriverFunctions(selectedLocationId).then(
        (res) => {
          setList(res.data);
        }
      );
    else setList(undefined);
    return () => {
      setRefreshFlag(false);
    };
  }, [selectedLocationId, refreshFlag]);

  return (
    <div className="location-main-container">
      <div>
        <FormControl required sx={{ mb: 5, mt: 5, minWidth: 360 }}>
          {locationList.length && (
            <LocationAutocomplete
              locationList={locationList}
              onSelectedLocationChange={onSelectedProjectChange}
            />
          )}
        </FormControl>
      </div>
      {selectedLocationId && (
        <>
          <Button
            className="new-driverfunction-button"
            sx={{
              backgroundColor: '#234C8A',
              color: '#ffff',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#ffff',
                color: '#234c8a',
              },
            }}
            variant="outlined"
            onClick={handleNewDriverFunction}
          >
            Add Driver Function
          </Button>
          {list?.length !== 0 && list !== undefined && (
            <DriverFunctionList list={list} setRefreshFlag={setRefreshFlag} />
          )}
        </>
      )}
      <AddDriverFunctionDialog
        open={openNewDriverFunction}
        setOpen={setOpenNewDriverFunction}
        driverFunctionData={newDriverFunction}
        setRefreshFlag={setRefreshFlag}
      ></AddDriverFunctionDialog>
    </div>
  );
};

export default DriverFunction;
