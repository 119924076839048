import { ILocationDetails } from 'interfaces/location.interface';
import { ITag } from './tag.interface';
import { UserTypes } from 'constants/user';

export type IUserDetails = {
  aadObjectId: string;
  avatar: string;
  emailAddress: string;
  id: number;
  isAdmin: boolean;
  isLocked: boolean;
  isSuperUser: boolean;
  linkedInId: string;
  locations?: ILocationDetails[];
  userType: UserTypes;
  secondaryEmail: string;
  description?: string;
};

export type UserIdAndEmail = Pick<IUserDetails, 'id' | 'emailAddress'>;

export interface IUserDetailsWithTags extends IUserDetails {
  tags: ITag[];
}

export enum DomainNames {
  'h2Digital' = '@h2-digital.com',
}

export interface IUserDetailsError {
  firstName?: string;
  lastName?: string;
  username?: string;
  secondaryEmail?: string;
  userAvatar?: string;
}

export type ICreateUserDto = {
  username: string;
  locationIds: number[];
  secondaryEmail: string;
  isAdmin: boolean;
  // isLocked: boolean;
  // isSuperUser: boolean;
  company?: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  position?: string;
  companyAddress?: string;
  companyType?: string;
  tagIds?: number[];
  userType: UserTypes;
  teamId?: string;
};

export interface UserCreationResponse {
  aadObjectId: string;
  emailAddress: string;
  principalName?: string;
  password: string;
  firstName?: string;
  lastName?: string;
  secondaryEmail?: string;
  company?: string;
  companyAddress?: string | null;
  companyType?: string | null;
  position?: string | null;
  phoneNumber?: string | null;
  userType?: UserTypes;
}

export type IInviteUserDto = Omit<
  ICreateUserDto,
  'firstName' | 'lastName' | 'username'
>;
