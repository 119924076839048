import HttpClient from '../httpClient/httpClient';
import { commonConstants } from 'constants/common';
import { IProjectUpdateDto } from 'interfaces/project.interface';
import { IProjectDetails } from 'interfaces/project.interface';
import { ILocationDetails } from 'interfaces/location.interface';
import { ITag } from 'interfaces/tag.interface';

class ProjectService {
  private azureAdScope = commonConstants.AZURE_AD_SCOPE;

  private httpClient;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
  }

  async createNewProvider(
    locationDetails: Partial<
      Omit<ILocationDetails, 'tags'> & { tags: Omit<ITag, 'id'>[] }
    >
  ) {
    return this.httpClient
      .post(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project-onboarding/provider`,
        locationDetails
      )
      .then((response) => {
        const result = response.data;
        return result;
      });
  }

  async uploadLogo(file: string | Blob, projectID: number) {
    const fd = new FormData();
    fd.append('file', file);
    const path = await this.httpClient.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/admin/logo/${projectID}`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return path.data.logo;
  }

  async uploadImage(file: string | Blob, projectID: number) {
    const fd = new FormData();
    fd.append('file', file);
    const path = await this.httpClient.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/admin/project-image/${projectID}`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return path.data.projectImage;
  }

  async getProjectAnalytics() {
    return this.httpClient
      .get(`${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/all/analytics`)
      .then(
        (response: {
          data: {
            totalCount: number;
            createdAt: Record<string, number>;
            updatedAt: Record<string, number>;
            deletedAt: Record<string, number>;
          };
        }) => {
          const result = response.data;
          return result;
        }
      );
  }

  async updateProjectDetails(
    projectData: IProjectUpdateDto,
    projectId: string
  ) {
    const response = await this.httpClient.patch(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/admin/update-project/${projectId}`,
      projectData
    );
    return response;
  }

  getProjectDetails(projectId: string): Promise<IProjectDetails> {
    return this.httpClient
      .get(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/findByAdmin/${projectId}`
      )
      .then((response: { data: IProjectDetails }) => {
        const result = response.data;
        return result;
      });
  }

  async deleteProject(projectId: number) {
    return this.httpClient
      .delete(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/admin/delete/${projectId}`
      )
      .then((response: { data: boolean }) => {
        const result = response.data;
        return result;
      });
  }
}
const SProjectService = new ProjectService();
export default SProjectService;
