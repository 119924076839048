import { useEffect, useState } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { LoadingComponent } from '../loading/Loading.component';
import SAuthService from '../../services/auth/auth.service';
import './AuthorizationWrapper.component.scss';

export const AuthorizationWrapperComponent = (props: {
  children: JSX.Element;
}) => {
  const isAuthenticated = useIsAuthenticated();
  const [isLoading, setIsloading] = useState(true);
  const { inProgress, instance, accounts } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      setTimeout(() => {
        if (accounts.length === 0) {
          instance.loginRedirect();
        }
      }, 500);
    }
    if (isAuthenticated) {
      SAuthService.setAccount(accounts[0]);
      setIsloading(false);
    }
  }, [isLoading, isAuthenticated, inProgress, accounts, instance]);

  return (
    <div className={!isAuthenticated ? 'auth-loading' : ''}>
      {' '}
      {isLoading ? <LoadingComponent /> : props.children}{' '}
    </div>
  );
};
