// @ts-nocheck
import {
  AccountInfo,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import { msalConfig } from 'config/authConfig';

export class AuthService {
  public msalInstance = new PublicClientApplication(msalConfig);

  private account: AccountInfo | null = null;

  setAccount(account: AccountInfo | null) {
    this.account = account;
  }

  getAccount() {
    return this.account;
  }

  logout() {
    this.msalInstance.logoutRedirect().then();
  }

  getAccessToken(scope: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      let response;
      try {
        response = await this.msalInstance.acquireTokenSilent({
          account: this.account,
          scopes: [scope],
        });
        if (response.accessToken) {
          resolve(response.accessToken);
        } else {
          reject(Error('Failed to acquire an access token'));
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          console.error(error);
        }
      }
    });
  }
}

const SAuthService = new AuthService();
export default SAuthService;
