import { FallbackProps } from 'react-error-boundary';
import './fallBack.component.scss';
import SAuthService from 'services/auth/auth.service';

export const FallBack = (props: FallbackProps) => {
  const { error } = props;
  if (error) console.log('ErrorStatus', error);

  const handleLogout = () => {
    SAuthService.logout();
  };
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404"></div>
        <h2>We are Sorry </h2>
        <p>You are not authorized to access this page. Please contact Admin</p>
        <a onClick={handleLogout}>Back to login</a>
      </div>
    </div>
  );
};
