import { SelectArrow } from 'components/SelectArrow/SelectArrow.component';
import { FormControl } from '@mui/material';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import React, { useEffect, useMemo, useState } from 'react';
import { ILocationMiniInfo } from 'interfaces/location.interface';
import CheckboxChecked from 'assets/svg/checkboxChecked.svg';
import CheckboxUnchecked from 'assets/svg/checkboxUnchecked.svg';
import SLocationService from 'services/location/location.service';
import { LocationType } from 'constants/location';

import './LocationMultiSelect.component.scss';
import { sortLocationsBySelectedAndName } from 'utils/location';

interface LocationMultiSelectProps {
  selectedLocations: number[] | ILocationMiniInfo[];
  locationSet?: ILocationMiniInfo[] | null;
  onChange: (selectedLocationsIds: ILocationMiniInfo[]) => void;
  disabled?: boolean;
  locationType?: LocationType;
}

export const LocationMultiSelect: React.FC<LocationMultiSelectProps> = ({
  selectedLocations,
  locationSet,
  onChange,
  disabled,
  locationType = LocationType.Project,
}) => {
  const [locationList, setLocationList] = useState<ILocationMiniInfo[] | null>(
    null
  );

  const isLocationIdArray = (value: unknown): value is number[] =>
    value instanceof Array && value.length > 0 && typeof value[0] === 'number';

  const selectedOptions = useMemo<ILocationMiniInfo[]>(() => {
    if (locationList && selectedLocations.length) {
      console.log(selectedLocations);
      if (isLocationIdArray(selectedLocations)) {
        return locationList.filter((location) =>
          selectedLocations.includes(location.id)
        );
      } else {
        return selectedLocations;
      }
    }
    return [];
  }, [locationList, selectedLocations]);

  const sortedLocationList = useMemo<ILocationMiniInfo[]>(() => {
    if (locationList) {
      return sortLocationsBySelectedAndName(locationList, selectedOptions);
    }
    return [];
  }, [locationList, selectedOptions]);

  useEffect(() => {
    if (locationSet) setLocationList(locationSet);
    else {
      SLocationService.findNames(locationType).then((response) => {
        if (response && response.length > 0) {
          setLocationList(response);
        }
      });
    }
  }, [locationType]);

  const renderDropdownOption = (option: ILocationMiniInfo) => {
    let isSelected = false;
    if (isLocationIdArray(selectedLocations)) {
      isSelected = selectedLocations.some(
        (locationId) => locationId === option.id
      );
    } else {
      isSelected = selectedLocations.some(
        (location) => location.id === option.id
      );
    }
    return (
      <div className="location-dropdown-option">
        <img
          src={isSelected ? CheckboxChecked : CheckboxUnchecked}
          className="multiselect-checkbox"
        />
        {option.name}
      </div>
    );
  };

  const renderSelectedItems = (option: ILocationMiniInfo) => {
    if (!option) return <></>;
    return <div className="selected-location-chip">{option.name}</div>;
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} className="form-label-loc-list">
      <span className="p-float-label">
        <MultiSelect
          value={selectedOptions}
          onChange={(e: MultiSelectChangeEvent) => {
            onChange(e.value);
          }}
          options={sortedLocationList}
          optionLabel="name"
          filter
          filterPlaceholder="search"
          resetFilterOnHide
          showClear={false}
          itemTemplate={renderDropdownOption}
          selectedItemTemplate={renderSelectedItems}
          disabled={disabled}
          dropdownIcon={<SelectArrow />}
          virtualScrollerOptions={{ itemSize: 40 }}
          panelClassName="assign-locations-multiselect-panel"
        />

        <label>Assign Locations *</label>
      </span>
    </FormControl>
  );
};
