const AZURE_AD_SCOPE = 'c7e22c72-04da-484f-8672-0ca9929210f2/default';
export const commonConstants = {
  AZURE_AD_SCOPE,
};

export const emailExpression =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export enum CustomErrorCode {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INTERNAL_USER_INVITATION_FORBIDDEN = 'INTERNAL_USER_INVITATION_FORBIDDEN',
  USER_ALREADY_INVITED = 'USER_ALREADY_INVITED',
  AAD_USER_NOT_FOUND = 'AAD_USER_NOT_FOUND',
  INVALID_REQUEST_SIGNATURE = 'INVALID_REQUEST_SIGNATURE',
}
