import {
  IEncodeDetails,
  ILocationMiniInfo,
  LocationData,
} from 'interfaces/location.interface';

export const locationInputValidator = (locationDetails: LocationData) => {
  let error = {};
  if (locationDetails.name?.trim() === '' || locationDetails.name === null) {
    error = {
      ...error,
      name: 'Name cannot be empty!',
    };
  }
  if (
    locationDetails.address?.trim() === '' ||
    locationDetails.address === null
  ) {
    error = {
      ...error,
      address: 'Address cannot be empty!',
    };
  }
  return error;
};

// with logo, location name as encrypted parent info, create public entry url
export const encodeURLPublicJoin = (locationDetails: IEncodeDetails) => {
  let dataToEncode = {};
  dataToEncode = {
    ...dataToEncode,
    parentLogo: locationDetails.logo,
    tags: [{ key: 'Provider', value: locationDetails.name }],
  };
  const encodedString = window.btoa(JSON.stringify(dataToEncode));
  navigator.clipboard.writeText(
    process.env.REACT_APP_H2_DIGITAL_URL + '/join?data=' + encodedString
  );
};

export const sortLocationsByName = (locations: ILocationMiniInfo[]) => {
  return locations.slice().sort((a, b) => a.name.localeCompare(b.name));
};

export const sortLocationsBySelectedAndName = (
  locations: ILocationMiniInfo[],
  selectedLocations: ILocationMiniInfo[]
) => {
  return locations.slice().sort((a, b) => {
    const isASelected = selectedLocations.find(
      (location) => a.id === location.id
    );
    const isBSelected = selectedLocations.find(
      (location) => b.id === location.id
    );
    if (isASelected && !isBSelected) return -1;
    if (!isASelected && isBSelected) return 1;
    return a.name.localeCompare(b.name);
  });
};
