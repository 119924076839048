import { NewTeamsResponse, TeamsFormData } from 'interfaces/teams.interface';
import HttpClient from '../../services/httpClient/httpClient';
import { commonConstants } from 'constants/common';

class TeamsService {
  private defaultScope = 'ChatMessage.Send';

  private azureAdScopeForClaims =
    'Tasks.ReadWrite  TeamMember.ReadWrite.All ChatMessage.Send';

  private httpClient;

  private httpClientApp;

  private httpClientClaims;

  constructor() {
    this.httpClient = new HttpClient(this.defaultScope).client;
    this.httpClientApp = new HttpClient(commonConstants.AZURE_AD_SCOPE).client;
    this.httpClientClaims = new HttpClient(this.azureAdScopeForClaims).client;
  }

  async sendNotificationInTeams(api: string, message: string) {
    const result = await this.httpClient.post(api, {
      body: {
        content: message,
      },
    });
    return result;
  }

  sendMessageFromExternalUser(data: {
    url: string;
    message: string;
  }): Promise<void> {
    return this.httpClient.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/webhook-test-notification`,
      data
    );
  }

  async createNewTeams(details: TeamsFormData): Promise<NewTeamsResponse> {
    return this.httpClientApp
      .post(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project/new-teams`,
        details
      )
      .then((response: { data: NewTeamsResponse }) => {
        const result = response.data;
        return result;
      });
  }

  async testTaskCreation(data: { teamId: string; title: string }) {
    return this.httpClientApp
      .post(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/project-onboarding/task-create`,
        data
      )
      .then((response: { data: string }) => {
        const result = response.data;
        return result;
      });
  }
}
const STeamsService = new TeamsService();
export default STeamsService;
