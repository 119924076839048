import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
} from '@mui/material';
import SLocationService from '../../services/location/location.service';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import './Location.scss';
import ListLocations from './ListLocations';
import LocationAutocomplete from '../../components/LocationAutocomplete/LocationAutocomplete';
import {
  ILocationMiniInfo,
  ILocationDetails,
} from 'interfaces/location.interface';
import { LocationType } from 'constants/location';
import { sortLocationsByName } from 'utils/location';

const Location: React.FunctionComponent = () => {
  const [selectedLocationId, setSelectedLocationId] = useState<number>();
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [selectedLocationList, setSelectedLocationList] = useState<
    ILocationDetails[]
  >([]);
  const [locationList, setLocationList] = useState<ILocationDetails[]>([]);
  const [selectedValue, setSelectedValue] = useState<number>(10);

  const handleAllLocations = (h2DigitalType: LocationType) => {
    SLocationService.getAllLocationByType([h2DigitalType])
      .then((response) => {
        setLocationList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    handleAllLocations(LocationType.Provider);
  }, []);

  const onSelectedProjectChange = (
    e: SyntheticEvent<Element, Event>,
    newValue: ILocationMiniInfo | null
  ) => {
    setSelectedLocationId(newValue?.id);
    // TODO: Rethink the use of this page and refactor the code
    const locations = locationList.find(
      (loc: ILocationDetails) => loc.id === newValue?.id
    );
    setSelectedLocationList(locations ? [locations] : []);
  };

  const handleDeletelocation = (id: string): void => {
    SLocationService.deleteLocation(id)
      .then(() => {
        setDeleteSuccess(true);
        setStatusOpen(true);
      })
      .catch((error) => {
        alert(error.message);
        setStatusOpen(true);
      });
  };

  const handleDeleteClose = () => {
    setStatusOpen(false);
    return;
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    const valueSelected = Number(event.target.value);
    setSelectedLocationList([]);
    handleAllLocations(valueSelected);
    setSelectedValue(valueSelected);
  };

  return (
    <div className="location-main-container">
      <div className="location-details-title"> Location</div>
      <div className="project-select-main-conatiner">
        <div className="projecttype-select-label">
          <FormControl sx={{ m: 1, minWidth: 300 }}>
            <InputLabel id="project-location-select-label">Type</InputLabel>
            <Select value={selectedValue} label="Type" onChange={handleChange}>
              <MenuItem value={LocationType.Provider}>Provider</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="project-select-location">
          <FormControl required sx={{ mb: 5, mt: 5, minWidth: 300 }}>
            {locationList.length > 0 && (
              <LocationAutocomplete
                label="Location*"
                locationList={sortLocationsByName(locationList)}
                onSelectedLocationChange={onSelectedProjectChange}
              />
            )}
          </FormControl>
        </div>
      </div>
      {selectedLocationId && (
        <ListLocations
          locationsList={selectedLocationList}
          handleDeletelocation={handleDeletelocation}
        />
      )}
      <Snackbar
        open={statusOpen}
        autoHideDuration={6000}
        onClose={handleDeleteClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <Alert
          onClose={handleDeleteClose}
          severity={deleteSuccess ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {deleteSuccess ? 'Deletion Successful' : 'Deletion  Failed'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Location;
