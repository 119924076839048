import { AxiosError } from 'axios';
import { CustomErrorCode } from 'constants/common';

interface ExceptionWithMessage {
  message: {
    error: string;
  };
}

const isEnum = (value: unknown): value is CustomErrorCode =>
  typeof value === 'string' &&
  (Object.values(CustomErrorCode) as string[]).includes(value);

export function getServerExceptionType(
  error: AxiosError
): CustomErrorCode | null {
  const customErrorCode = (error?.response?.data as ExceptionWithMessage)
    ?.message?.error;
  if (isEnum(customErrorCode)) return customErrorCode;
  return null;
}
