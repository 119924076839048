import { Button } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import './Project.scss';
import SUserService from '../../services/user/user.service';
import SProjectBulkUploadService from '../../services/projectBulkUpload/projectBulkUpload.service';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { User } from '@microsoft/microsoft-graph-types';

export const DataLoad: FunctionComponent = () => {
  const [user, setUser] = useState<Partial<User>>({
    displayName: '',
    mail: '',
    userPrincipalName: '',
  });
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const allowedUsers = [
    'amal.sukumaran@h2-digital.com',
    'likhita.inampudi@h2-digital.com',
  ];

  useEffect(() => {
    SUserService.getUser().then((response: User) => {
      setUser(response);
    });
  }, []);

  const isAllowedUser = allowedUsers.some(
    (principalName) => principalName === user?.userPrincipalName
  );

  const handleDataFlush = () => {
    setLoading(true);
    showSnackbar('Data Load is under Progress!', 'info');
    SProjectBulkUploadService.flushAndLoadH2DigitalProjects()
      .then(() => {
        showSnackbar('Data Load is Complete!', 'success');
        setLoading(false);
      })
      .catch(() => {
        showSnackbar('Data Load Failed!', 'error');
        setLoading(false);
      });
  };
  return (
    <>
      {isAllowedUser && process.env.REACT_APP_ENV === 'dev' ? (
        <div className="data-load-container">
          <Button
            variant="contained"
            onClick={handleDataFlush}
            disabled={loading}
          >
            Flush and Load H2Digital Projects
          </Button>
        </div>
      ) : null}
    </>
  );
};
