import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { FaEdit, FaRegTrashAlt } from 'react-icons/fa';
import { IReportDetails } from 'interfaces/reports.interface';

import { TableWrapper, EmptyList } from './style';
import { LoadingComponent } from '../../components/loading/Loading.component';

interface IListReports {
  loading: boolean;
  reportsList: (Partial<IReportDetails> & { id: string })[];
  onReportEdit: (report: Partial<IReportDetails> & { id: string }) => void;
  handleDeleteReport: (id: string) => void;
  isTSOSetup?: boolean;
}

const ListReports = (props: IListReports) => {
  const { reportsList, loading, onReportEdit, handleDeleteReport, isTSOSetup } =
    props;
  const [open, setOpen] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState('');

  const handleClickOpen = (reportId: string) => {
    setSelectedReportId(reportId);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedReportId('');
    setOpen(false);
  };

  const onDeleteConfirm = useCallback(() => {
    handleDeleteReport(selectedReportId);
    handleClose();
  }, [handleDeleteReport, selectedReportId]);

  return (
    <>
      {loading ? (
        <div style={{ marginBottom: '20px' }}>
          <LoadingComponent />
        </div>
      ) : (
        <>
          {reportsList.length ? (
            <TableWrapper>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow selected>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left">ReportId</TableCell>
                      <TableCell align="left">DatasetId</TableCell>
                      <TableCell align="left">WorkspaceId</TableCell>
                      <TableCell align="left">ReportName</TableCell>
                      <TableCell align="left">RoleName</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportsList.map((report) => (
                      <TableRow
                        key={report.reportId}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          {report.powerBIReportTypes}
                        </TableCell>
                        <TableCell align="left">{report.reportId}</TableCell>
                        <TableCell align="left">{report.datasetId}</TableCell>
                        <TableCell align="left">{report.workspaceId}</TableCell>
                        <TableCell align="left">{report.reportName}</TableCell>
                        <TableCell align="left">{report.roleName}</TableCell>
                        <TableCell align="left">
                          <IconButton
                            onClick={() => {
                              onReportEdit(report);
                            }}
                          >
                            <FaEdit color="blue" />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              if (report.id) handleClickOpen(report.id);
                            }}
                          >
                            <FaRegTrashAlt color="red" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Delete Report</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to delete this report permanently ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button color="error" onClick={onDeleteConfirm} autoFocus>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </TableWrapper>
          ) : (
            !isTSOSetup && <EmptyList>No Reports Yet !</EmptyList>
          )}
        </>
      )}
    </>
  );
};

export default ListReports;
