import { Autocomplete, TextField } from '@mui/material';
import {
  ChangeEvent,
  FunctionComponent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import './TagSearch.component.scss';

import createTagDisabledIcon from 'assets/svg/createTagDisabled.svg';
import createTagEnabledIcon from 'assets/svg/createTagEnabled.svg';
import warningTriangleIcon from 'assets/svg/warningTriangle.svg';
import STagsService from 'services/tags/tags.service';
import { isMatch } from 'lodash';
import { CreateTagDto, ITag } from 'interfaces/tag.interface';
import useDebounce from 'hooks/useDebounce';

interface AddTagsProps {
  className?: string;
  searchFieldsClassName?: string;
  onSubmit: (newTag: CreateTagDto) => void;
  isCreateTagAllowed: boolean;
  isPossibleToSelect: (newTag: CreateTagDto) => boolean;
  notPossibleToSelectMessage: string;
}

export const TagSearch: FunctionComponent<AddTagsProps> = ({
  className,
  searchFieldsClassName,
  onSubmit: onChange,
  isCreateTagAllowed,
  isPossibleToSelect,
  notPossibleToSelectMessage,
}) => {
  const [search, setSearch] = useState<CreateTagDto>({ key: '', value: '' });
  const [settled, setSettled] = useState<CreateTagDto>({ key: '', value: '' });
  const [errorText, setErrorText] = useState<string>('');

  const tagAlreadyExistsMessage = 'tagAlreadyExists';

  const parameters = useMemo(
    () => ({
      key: settled.key ?? undefined,
      value: settled.value ?? undefined,
    }),
    [settled]
  );

  const debouncedSearchParameters = useDebounce(parameters, 250);
  const [keyOptions, setKeyOptions] = useState<string[]>([]);
  const [valueOptions, setValueOptions] = useState<string[]>([]);
  const { data: filteredTagsData, isFetching: isFilteredTagsFetching } =
    STagsService.useFilterTags(debouncedSearchParameters);

  useEffect(() => {
    if (filteredTagsData) {
      const keys: string[] = [];
      const values: string[] = [];
      for (const tag of filteredTagsData) {
        keys.push(tag.key);
        values.push(tag.value);
      }

      const uniqueKeys = Array.from(new Set(keys));
      const keysWithPartialMatch = uniqueKeys
        .filter(
          (key) =>
            !search.key || key.toLowerCase().includes(search.key.toLowerCase())
        )
        .sort();

      const uniqueValues = Array.from(new Set(values));
      const valuesWithPartialMatch = uniqueValues
        .filter(
          (value) =>
            !search.value ||
            value.toLowerCase().includes(search.value.toLowerCase())
        )
        .sort();

      setKeyOptions(keysWithPartialMatch);
      setValueOptions(valuesWithPartialMatch);
    }
  }, [filteredTagsData, search]);

  const isCreateTagEnabled = () => {
    return (settled.key && search.value) || (settled.value && search.key);
  };

  const clearSearch = () => {
    setSettled({ key: '', value: '' });
    setSearch({ key: '', value: '' });
  };

  const createOrFindTag = (key: string, value: string) => {
    const newTag = { key, value };
    const existingTag = filteredTagsData?.find((Tag: Partial<ITag>) =>
      isMatch(Tag, newTag)
    );
    return existingTag ?? newTag;
  };

  const addTag = (key: string, value: string) => {
    onChange(createOrFindTag(key, value));
    clearSearch();
    setErrorText('');
  };

  useEffect(() => {
    if (filteredTagsData && settled.key && settled.value) {
      const tagExists = filteredTagsData.find(
        (tag: ITag) => tag.key === settled.key && tag.value === settled.value
      );
      const isNotPossibleToSelect = !isPossibleToSelect(
        createOrFindTag(settled.key, settled.value)
      );
      if (tagExists && !isNotPossibleToSelect) {
        addTag(settled.key, settled.value);
      } else {
        if (isNotPossibleToSelect) {
          setErrorText(notPossibleToSelectMessage);
        }
        // We don't need to block Create button here by setting errorText when tag doesn't exist!
      }
    }
  }, [settled]);

  const onKeyInput = (key: string) => {
    setSearch({ key, value: search.value });
    setErrorText('');
  };

  const onKeySelect = (key: string) => {
    setSettled({ key, value: settled.value });
    onKeyInput(key);
  };

  const onKeyFocus = () => {
    setSettled({ key: '', value: settled.value });
    setErrorText('');
  };

  const onKeyBlur = useCallback(() => {
    setSettled({ key: search.key, value: settled.value });
  }, [search]);

  const onKeyClear = (event: SyntheticEvent) => {
    clearSearch();
    setErrorText('');
    event.stopPropagation();
    event.preventDefault();
  };

  const onValueInput = (value: string) => {
    setSearch({ key: search.key, value });
    setErrorText('');
  };

  const onValueSelect = (value: string) => {
    setSettled({ key: settled.key, value });
    onValueInput(value);
  };

  const onValueFocus = () => {
    setSettled({ key: settled.key, value: '' });
    setErrorText('');
  };

  const onValueBlur = useCallback(() => {
    setSettled({ key: settled.key, value: search.value });
  }, [search]);

  const createTag = useCallback(async () => {
    if (search.key && search.value) {
      if (
        keyOptions.includes(search.key) &&
        valueOptions.includes(search.value)
      ) {
        setErrorText(tagAlreadyExistsMessage);
      } else {
        addTag(search.key, search.value);
      }
    }
  }, [tagAlreadyExistsMessage, search]);

  const onClickErrorMessage = () => setErrorText('');

  return (
    <div className={`tag-search ${className ?? ''}`}>
      <div
        className={`search-field-with-two-inputs ${
          errorText ? 'red-border' : ''
        }`}
      >
        <Autocomplete
          fullWidth
          disableClearable={!search.key}
          componentsProps={{
            clearIndicator: {
              title: 'clear',
            },
          }}
          className="key-search-field"
          forcePopupIcon={false}
          loading={isFilteredTagsFetching}
          freeSolo
          onChange={(event, value) => {
            if (value) {
              onKeySelect(value ?? '');
            }
          }}
          onInputChange={(event, value, reason) => {
            // handle change on input clear with a button
            if (reason === 'clear') {
              onKeyClear(event);
            }
          }}
          onBlur={onKeyBlur}
          onFocus={onKeyFocus}
          value={search.key}
          options={keyOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onKeyInput(e.target.value);
              }}
              className={`${errorText ? 'red-label' : ''} ${
                searchFieldsClassName ?? ''
              }`}
              label="Key"
            />
          )}
        />
        <Autocomplete
          fullWidth
          disableClearable={!search.value}
          componentsProps={{
            clearIndicator: {
              title: 'clear',
            },
          }}
          className="value-search-field"
          forcePopupIcon={false}
          loading={isFilteredTagsFetching}
          freeSolo
          disabled={!search.key}
          onChange={(event, value) => {
            onValueSelect(value ?? '');
          }}
          onInputChange={(event, value, reason) => {
            if (reason === 'clear') {
              onValueInput('');
              setErrorText('');
            }
          }}
          onBlur={onValueBlur}
          onFocus={onValueFocus}
          value={search.value}
          options={valueOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onValueInput(e.target.value);
              }}
              className={`${errorText ? 'red-label' : ''} ${
                searchFieldsClassName ?? ''
              }`}
              label="Value"
            />
          )}
        />
        {errorText ? (
          <div className="error-container" onClick={onClickErrorMessage}>
            <img src={warningTriangleIcon} className="error-icon" />
          </div>
        ) : (
          isCreateTagAllowed && (
            <img
              src={
                isCreateTagEnabled()
                  ? createTagEnabledIcon
                  : createTagDisabledIcon
              }
              className={`create-tag-button ${
                isCreateTagEnabled() ? 'enabled' : ''
              }`}
              onClick={createTag}
            />
          )
        )}
      </div>
      {errorText && <span className="error-text">{errorText}</span>}
    </div>
  );
};
