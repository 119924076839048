/* eslint-disable @typescript-eslint/no-explicit-any */
/*******************************************************
Copyright (C) 2024 H2 Digital GmbH - info@h2-digital.com , Inc - All Rights Reserved
Proprietary and confidential.
Unauthorized copying of this file, via any medium is strictly prohibited.
Written by Tanuja Sathyanarayana, tanuja.sathyanarayana@h2-digital.com, November 2024
*******************************************************/
import { FunctionComponent, useEffect, useState } from 'react';
import './support.page.scss';
import PowerBILoadingComponent from 'components/powerBI/PowerBILoading.component';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Embed, models } from 'powerbi-client';
import { IPowerBIDetails } from 'interfaces/powerBi.interface';
import SPowerBIService from 'services/powerBI/powerBI.service';
import { getPowerBIEmbedConfig } from 'constants/reports';

export const SupportPage: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [reportComponent, setReportComponent] = useState<Embed>();
  const [reportDetails, setReportDetails] = useState<IPowerBIDetails>();

  useEffect(() => {
    SPowerBIService.getSupportDashboard()
      .then((reportData) => {
        setReportDetails(reportData);
      })
      .catch((err) => {
        console.error('Error fetching report details:', err);
      });
  }, []);

  const powerBIEmbedConfig = getPowerBIEmbedConfig(
    reportDetails?.id,
    reportDetails?.token,
    reportDetails?.embedURL
  );

  useEffect(() => {
    reportComponent?.on('rendered', async (event: any) => {
      const pages = await event?.srcElement?.powerBiEmbed.getPages();
      console.log(pages.map((item: { name: any }) => item.name)); // Log pages instead of setting state
    });

    reportComponent?.on('error', (event: any) => {
      const err = event.detail; // Renamed to 'err'
      setLoading(false);
      // If the error level isn't Fatal, log the error and continue.
      if (err?.level !== models.TraceType.Fatal) {
        console.error(err);
        return;
      } else {
        setError(true);
      }
    });

    return () => {
      reportComponent?.off('rendered');
      reportComponent?.off('pageChanged');
      reportComponent?.off('error');
    };
  }, [reportComponent]);

  return (
    <div className="outer-page-container">
      <div className="report-outer-container">
        {loading && <PowerBILoadingComponent />}
        {reportDetails?.embedURL && !error ? (
          <>
            <PowerBIEmbed
              getEmbeddedComponent={(embeddedReport) => {
                setReportComponent(embeddedReport);
              }}
              embedConfig={powerBIEmbedConfig}
              cssClassName="report"
            />
          </>
        ) : (
          <PowerBILoadingComponent />
        )}
      </div>
    </div>
  );
};
