import { TagComponent } from 'components/Tag/tag.component';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ITag } from 'interfaces/tag.interface';
import './AddTags.Component.scss';
import { useEffect, useState } from 'react';
import { TagFilter } from 'components/tagFilter/TagFilter.component';
import SUserService from 'services/user/user.service';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { IUserDetailsWithTags } from 'interfaces/user.interface';
import { useMutation, useQueryClient } from 'react-query';

interface IAddUsersProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedUser: IUserDetailsWithTags;
  usersList?: IUserDetailsWithTags[];
  refreshUsers?: () => void;
}

const AddTags = (props: IAddUsersProps) => {
  const { open, setOpen, selectedUser, usersList } = props;
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: mutateLinkTagsToUser } = useMutation(
    (parameters: { userId: number; tagsId: number[] }) =>
      SUserService.linkTagsToUser(parameters.userId, parameters.tagsId),
    {
      onSuccess: (data) => {
        console.log(data);
        showSnackbar('Tags updated successfully!', 'success');
      },
      onError: () => {
        showSnackbar('Update Tags failed!', 'error');
      },
      onSettled: () => {
        queryClient.invalidateQueries('/user-details/all/tags');
      },
    }
  );

  useEffect(() => {
    if (selectedUser) {
      setSelectedTags(selectedUser.tags);
    }
  }, [selectedUser]);

  const handleClose = () => {
    setOpen(false);
  };

  const deleteTags = (id: number) => {
    setSelectedTags(selectedTags.filter((tag) => tag.id !== id));
  };

  const saveTagsToUser = () => {
    mutateLinkTagsToUser({
      userId: selectedUser.id,
      tagsId: selectedTags
        .filter((tag) => tag.id !== undefined)
        .map((tag) => tag.id),
    });
  };

  const handleChange = (event: SelectChangeEvent) => {
    const user: IUserDetailsWithTags = JSON.parse(event.target.value);
    setSelectedTags(user.tags);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      className="add-tags-dialog"
    >
      <DialogTitle id="alert-dialog-title">
        Link Tags to <strong>{selectedUser.emailAddress}</strong>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Copy Tags from User</InputLabel>
          <Select label="Copy Tags from User" onChange={handleChange}>
            {usersList?.map((user: IUserDetailsWithTags) => (
              <MenuItem key={user.id} value={JSON.stringify(user)}>
                {user.emailAddress}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TagFilter
          tags={selectedTags}
          onChange={(tags: ITag[]) => {
            setSelectedTags(tags);
          }}
        />

        <div className="tags-container-pop-up">
          {selectedTags.map((tag: ITag) => {
            return (
              <TagComponent
                key={tag.id}
                deleteEnabled={true}
                deleteAction={deleteTags}
                tag={tag}
              ></TagComponent>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            setSelectedTags(selectedUser.tags);
          }}
        >
          Reset
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="success" onClick={saveTagsToUser}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTags;
